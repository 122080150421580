a {
  cursor: pointer;
}

.label {
  background-color: #d1dade;
  color: #5e5e5e;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

  padding: 3px 8px;
  text-shadow: none;
  border-radius: 0.25em;
  line-height: 1;
  white-space: nowrap;
}

.label-warning,
.badge-warning {
  background-color: #f8ac59;
  color: #ffffff;
}

.label-primary,
.badge-primary {
  background-color: #1ab394;
  color: #ffffff;
}

.nav .label,
.ibox .label {
  font-size: 10px;
}

.nav-item .label-warning,
.label-primary {
  margin-top: 8px;
}

.btn-nav {
  background-color: $yellow;
}

.custom-file-label {
  font-size: 15px;
  height: 35px;
  padding: 0.3rem 0.75rem;
}

.custom-file-label::after {
  font-size: 15px;
  height: 35px;
  padding: 0.3rem 0.75rem;
  background-color: transparent;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background: none;
  padding-right: 12px;
}


/*material overwrite*/
.mat-sort-header-button {
  font-size: 14px !important;

}

.search {
  .form-control {
    &:focus {

      &,
      &+.input-group-append .search-icon {
        background-color: $gray-100  !important;
      }
    }
  }

  input {
    border-right: none;
  }

  .search-icon {
    width: 25px;
    padding-left: 2px;
    padding-right: 24px;
    height: 36px;
    background-color: transparent;
    color: $gray-600;
    border: 1px solid $gray-400;
    border-left: none;
    box-shadow: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    i {
      top: 6px;
      position: relative;
      left: -1px;
    }
  }
}

.mat-datepicker {
  .mat-form-field-flex {
    border-radius: 4px !important;
    // padding: 0 3px 0 10px !important;
    border: 1px solid $gray-400  !important;
    margin-top: 3px;
    //padding-bottom: 2px;
  }

  .mat-form-field-infix {
    padding: 0 0 0 8px !important;
    border-top: 0px;
    margin-top: 7px;
    margin-bottom: 3px;
  }
}

.mat-form-field-prefix,
.mat-form-field-suffix {
  top: -2px;
}

.mat-input-underline {
  display: none;
}

.mat-form-field-underline {
  display: none;
}

.mat-form-field-appearance-legacy .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-form-field-appearance-legacy .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  width: 1.5em !important;
}

.mat-calendar-table-header th {
  line-height: 1em;
}

.mat-cell,
.mat-footer-cell {
  font-size: 15px;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0.5em;
}

.mat-paginator-range-actions {
  margin-top: 10px;
}

.mat-header-cell {
  font-size: 14px;
}

.mat-mdc-header-row {
  min-height: 40px;
}

.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 0.54 !important;
  transform: translateY(0px) !important;
}

.mat-cell,
.mat-header-cell {
  display: block;
  padding-right: 10px;
}

.mat-cell {
  padding-top: 5px;
}

.mat-row {
  min-height: 40px;
}

.material-icons {
  font-size: 20px;
  vertical-align: middle;
}

/***************************/

.content-center {
  //  display: flex;
  text-align: center !important;
}

.form-inline label {
  margin-bottom: 5px;
}

.nav-tabs {
  background-color: #f8f9fa;

  a {
    letter-spacing: 1px;
    color: #2c9199;
    font-size: 17px;
  }
}

.card-body-narrow {
  padding: 0.75rem;
}

.form {
  label {
    font-weight: bold;
    font-size: 16px;

  }

}

.form-edit {
  .row {
    line-height: 40px;
  }

}

.form-title {
  font-size: 18px !important;
  margin-bottom: 0px;
}

.red {
  color: #dc3545;
}

.amber {
  color: #f8ac59;
}

.green {
  color: #28a745;
}

.gray {
  color: $gray-400;
}

.default {
  color: $gray-600;
}

.w-50 {
  max-width: 50px;
}

.w-80 {
  max-width: 80px;
}

.w-100 {
  max-width: 100px;
}

.w-120 {
  max-width: 120px;
}

.w-150 {
  max-width: 150px;
}

.w-200 {
  max-width: 200px;
}

.w-250 {
  max-width: 250px;
}

.child-card {
  .close-icon {
    font-size: 35px;
  }

  .card-header {
    padding-bottom: 0.4rem;

    .btn-fab {
      height: 36px;
      min-width: 36px;
      width: 36px;
      margin-top: -2px;

      i {
        margin-top: -2px;
      }
    }
  }

  .nav-tabs {
    .material-icons {
      font-size: 18px;
    }
  }

}

.card-footer {
  background-color: transparent;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.partialcpt {
  color: $brand-primary ;
}

.dirtycpt {
  color: red;
}
.mat-datepicker{
    .mat-mdc-input-element{
      font-family: Roboto, "Helvetica Neue", sans-serif;
      font: inherit;
      background: transparent;
      color: rgb(60, 72, 88) !important;
    }
    .mat-mdc-text-field-wrapper{
      height: 35px !important;
      border: 1px solid #00000024;
      border-radius: 5px !important;
    }
    .mdc-text-field--no-label .mat-mdc-form-field-infix {
      padding-top: 6px !important;
      padding-left: 4px !important;
    }
    .mat-mdc-text-field-wrapper{
      padding-left: 5px;
    }
    .mdc-icon-button{
      top: -10px;
      right: -2px;
    }
    .mdc-text-field--filled{
      background-color: white !important;
    }
    .mdc-line-ripple::before, .mdc-line-ripple::after{
      width: 0% !important;
    }
    .mat-mdc-form-field-subscript-wrapper{
      height: 0px !important;
    }
    .mat-mdc-form-field-infix{
      padding: 0% !important;
      min-height: 40px;
    }
    .mat-form-field-flex{
      border-radius: 4px !important;
      border: 1px solid #ced4da !important;
      margin-top: 3px;
    }
    .mat-mdc-icon-button.mat-mdc-button-base {
      padding-left: 0% !important;
      padding-right: 0% !important;
   }
   .mat-mdc-icon-button .mat-mdc-button-touch-target{
    width: 35px !important;
    height: 35px !important;
   }
   .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 30px;
   }
   .mat-mdc-icon-button svg {
    vertical-align: middle;
}
}
.mat-datepicker {
   width: 100%;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
}
