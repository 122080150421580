.table {
    > thead > tr > th {
        border-bottom-width: 1px;
        font-size: $font-size-h5;
        font-weight: $font-weight-light;
    }

    .form-check {
        margin-top: 0;

        .form-check-sign {
            top: -13px;
            left: 0;
            padding-right: 0;
        }
    }

    .radio,
    .checkbox {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        width: 15px;

        .icons {
            position: relative;
        }
    }
    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td {
        padding: 12px 8px;
        vertical-align: middle;
    }

    thead {
        tr {
            th {
                font-size: 1.063rem;
            }
        }
    }

    .th-description {
        max-width: 150px;
    }
    .td-price {
        font-size: 26px;
        font-weight: $font-weight-light;
        margin-top: 5px;
        text-align: right;
    }
    .td-total {
        font-weight: $font-weight-bold;
        font-size: $font-size-h5;
        padding-top: 20px;
        text-align: right;
    }

    .td-actions .btn {
        margin: 0px;
        padding: 5px;
    }

    > tbody > tr {
        position: relative;
    }
}

.table-shopping {
    > thead > tr > th {
        font-size: $font-size-h6;
        text-transform: uppercase;
    }
    > tbody > tr > td {
        font-size: $font-paragraph;

        b {
            display: block;
            margin-bottom: 5px;
        }
    }
    .td-name {
        font-weight: $font-weight-default;
        font-size: 1.5em;
        line-height: 1.42857143;

        small {
            color: $gray-light;
            font-size: 0.75em;
            font-weight: $font-weight-light;
        }
    }
    .td-number {
        font-weight: $font-weight-light;
        font-size: $font-size-h4;
    }
    .td-name {
        min-width: 200px;
    }
    .td-number {
        text-align: right;
        min-width: 150px;

        small {
            margin-right: 3px;
        }
    }

    .img-container {
        width: 120px;
        max-height: 160px;
        overflow: hidden;
        display: block;

        img {
            width: 100%;
        }
    }
}

// BS4 has not yet converted the following to variables - keep an eye on it and move to variables when possible.
.thead-inverse {
    th {
        //color: $bmd-inverse; // #fff;
        //background-color: $gray-dark;
    }
}
.thead-default {
    th {
        //color: $gray;
        //background-color: $bmd-inverse-lighter; //$gray-lighter;
    }
}

.table-inverse {
    color: $bmd-inverse-light; //$gray-lighter;
    //background-color: $gray-dark;

    //th,
    //td,
    //thead th {
    //  border-color: $gray;
    //}
}

.table {
    thead th {
        font-size: $bmd-table-header-font-size;
        font-weight: 500;

        border-top-width: 0;
        border-bottom-width: $table-border-width;
    }
}

thead.thead-inverse,
// needs specificity
.table-inverse thead {
    th {
        color: $bmd-inverse-lighter;
    }
}

.table-inverse {
    th,
    td,
    thead th {
        border-color: $bmd-table-border-color-inverse;
    }
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.table.table-hover tbody tr:hover {
    background-color: #f5f5f5;
}

.mat-table {
    .mat-row {
        cursor: pointer;
        &:hover {
            background-color: $blue-grey-50;
        }
    }
}

.norecords {
    margin-left: 45%;
    padding: 1em;
    font-weight: bold;
}

mat-footer-cell.norecords::after {
    content: "No Records found.";
}

// mat-mdc-table css
.mat-mdc-tab-group {
  display: flex;
  flex-direction: column;
  color: #3C4858;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.mat-mdc-tab-body-content {
  overflow-y: hidden !important;
}
mat-table{

  display: block;
  color: #3C4858;
  font-weight: 300;

  .mat-mdc-table {
    table-layout: auto;
    white-space: normal;
    background: #fff;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
.mat-mdc-header-row {
  // padding-top: 5px;
  min-height: 40px;
}
.mat-mdc-header-cell {
  font-size: 14px;
  color: rgba(0, 0, 0, .54);
}
.mat-mdc-sort-header-container {
  display: flex;
  cursor: pointer;
  align-items: center;
  letter-spacing: normal;
  outline: 0;
}
.mat-mdc-cell, .mat-mdc-header-cell {
  cursor: pointer;
  display: block;
  padding-right: 10px;
  padding-left: 0%;
  border: none;
}
mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type {
  padding-left: 24px;
}
mat-cell, mat-header-cell, mat-footer-cell {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  word-wrap: break-word;
}
mat-row, mat-header-row, mat-footer-row {
  display: flex;
  border-width: 0;
  border-bottom-width: 1px !important;
  border-style: solid !important;
  align-items: center;
  border-bottom-color: rgba(0, 0, 0, .12) !important;

  box-sizing: border-box;
}
.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-sort-header-content {
  text-align: center;
  display: flex;
  align-items: center;
}
.mat-mdc-table .mat-mdc-row {
  cursor: pointer;
}
.mat-mdc-row {
  align-content: center;
  min-height: 40px !important;
  line-height: 1.5em;
}
mat-cell.mat-mdc-cell {
  line-height: 24px;
  align-content: center;
  min-height: 40px !important;
  font-weight: 300;
  // padding-top: 5px;
}
.mat-mdc-cell, .mat-mdc-footer-cell {
  font-size: 15px;
  color: rgba(0, 0, 0, .87);
}
.mat-mdc-table mat-header-row.mat-mdc-header-row, .mat-mdc-table mat-row.mat-mdc-row, .mat-mdc-table mat-footer-row.mat-mdc-footer-cell {
  height: 40px;
}
.mdc-data-table__row:last-child .mat-mdc-cell{
    // border-bottom: 1px solid rgba(117, 117, 117, 0.301) !important;
  }
}

// paginator css
mat-paginator{
  font-family: Roboto, "Helvetica Neue", sans-serif;
  .mat-mdc-paginator-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
    flex-wrap: wrap-reverse;
    width: 100%;
}
.mat-mdc-paginator-page-size {
  margin: 0%;
}
.mdc-notched-outline {
  display: none !important;
}
.mat-mdc-paginator-page-size-label, .mat-mdc-paginator-range-label {
  color: #0000008A !important;
}
.mat-mdc-paginator-page-size-label{
  margin: 0% !important;
}
.mat-mdc-paginator-range-actions {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
mat-mdc-text-field-wrapper.mdc-text-field{
padding: 0%;
}
.mat-mdc-paginator-page-size-select {
margin: 6px 4px 0 4px;
}
.mat-mdc-option{
align-items: center !important;
}
.mat-mdc-option .mdc-list-item__primary-text{
margin: 0% !important;
}
.mat-mdc-icon-button {
  padding: 4px !important;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
}
mat-icon-button.mat-mdc-paginator-navigation-previous, mat-icon-button.mat-mdc-paginator-navigation-next{
  padding: 0%;
}
.mat-mdc-icon-button .mat-mdc-button-touch-target, .mat-mdc-icon-button .mat-mdc-button-ripple, .mat-mdc-focus-indicator {
  width: 40px;
}
.mat-mdc-select-panel{
  transform-origin: 50% 18.3984px 0px;
  font-size: 12px;
  opacity: 1;
  min-width: calc(100% + 32px);
  transform: scaleY(1);
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
  color: #3f51b5;
}
.mat-mdc-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    max-width: 100%;
    position: relative;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    box-sizing: border-box;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}
.mat-mdc-select-panel .mat-mdc-optgroup-label, .mat-mdc-select-panel .mat-mdc-option {
  font-size: inherit;
  line-height: 3em;
  height: 3em;
}
.cdk-overlay-pane .mat-mdc-select-panel-above{
 // min-width: 56px;
 pointer-events: auto;
 font-size: 12px !important;
 transform: translateX(-16px) translateY(-10px) !important;
 position: absolute !important;
 box-sizing: border-box !important;
 z-index: 1000 !important;
 display: flex;
 max-width: 100%;
 max-height: 100%;
}
.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
}
.mat-mdc-icon-button svg, .mat-mdc-icon-button img {
  width: 28px;
  height: 28px;
}
}
