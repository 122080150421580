/*
.nav-tabs {
    border: 0;
    border-radius: $border-radius-base;
    padding: 0 15px;

    .nav-item {
        .nav-link {
            color: $white-color;
            border: 0;
            margin: 0;
            border-radius: $border-radius-base;
            line-height: $mdb-btn-font-size-base * 2;
            text-transform: uppercase;
            font-size: $mdb-btn-font-size-base;
            padding: 10px 15px;
            background-color: transparent;
            transition: 0.3s background-color 0s;

            &:hover {
                border: 0;
            }
        }
        .nav-link,
        .nav-link:hover,
        .nav-link:focus {
            border: 0 !important;
            color: $white-color !important;
            font-weight: $font-weight-bold;
        }
        &.disabled .nav-link,
        &.disabled .nav-link:hover {
            color: rgba(255,255,255,0.5);
        }

        .material-icons{
            margin: -1px 5px 0 0;
        }

        .nav-link.active{
            background-color: rgba(255,255,255, .2);
            transition: 0.3s background-color 0.2s;
        }
    }
}


.nav-tabs {
  .nav-link {
    border-bottom: $bmd-nav-tabs-border-size solid transparent;
  }

  // colors
  @include bmd-tabs-color($bmd-nav-tabs-color, $bmd-nav-tabs-active-color, $bmd-nav-tabs-active-border-color, $bmd-nav-tabs-disabled-link-color, $bmd-nav-tabs-disabled-link-color-hover);

  &.header-primary {
    @include bmd-tabs-color($bmd-nav-tabs-primary-color, $bmd-nav-tabs-primary-active-color, $bmd-nav-tabs-primary-active-border-color, $bmd-nav-tabs-primary-disabled-link-color, $bmd-nav-tabs-primary-disabled-link-color-hover);
  }

  &.bg-inverse {
    @include bmd-tabs-color($bmd-nav-tabs-inverse-color, $bmd-nav-tabs-inverse-active-color, $bmd-nav-tabs-inverse-active-border-color, $bmd-nav-tabs-inverse-disabled-link-color, $bmd-nav-tabs-inverse-disabled-link-color-hover);
  }
}



.card-nav-tabs{
    margin-top: 45px;

    .card-header{
        margin-top: -30px !important;
    }
}

.tab-content .tab-pane .td-actions{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}ca

.card .tab-content .form-check{
  margin-top: 6px;
}
 */


 .mdc-tab{
  min-width: 160px !important;
 }
 .mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before {
  background-color: transparent !important;
}
.mat-mdc-tab .mat-ripple-element {
  background-color: #000000DE !important;
}
.mat-mdc-tab .mdc-tab__ripple::before{
  background-color: transparent !important;
}
.mat-mdc-tab-group {
  display: flex;
  flex-direction: column;
}
.mat-mdc-tab-group[mat-stretch-tabs] > .mat-mdc-tab-header .mat-mdc-tab-labels {
  flex-basis: 0;
  flex-grow: 1;
}
.mdc-tab__ripple:not(:empty) {
  transform: translateZ(0);
}
.mat-mdc-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  // transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
}
.mat-mdc-tab-body {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  flex-basis: 100%;
}
.mat-mdc-tab-body.mat-tab-body-active {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
  flex-grow: 1;
}
.mat-mdc-tab-group.mat-tab-group-dynamic-height .mat-mdc-tab-body.mat-tab-body-active {
  overflow-y: hidden;
}

.mat-mdc-tab-header {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(117, 117, 117, 0.301);
}
.mat-mdc-tab-header-pagination {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  cursor: pointer;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
}
.mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
  display: flex;
}
.mat-mdc-tab-header-pagination-chevron {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: "";
  height: 8px;
  width: 8px;
}
.mat-mdc-tab-header-pagination-disabled {
  box-shadow: none;
  cursor: default;
}
.mat-mdc-tab-list {
  flex-grow: 0 !important;
  position: relative;
}
.mat-ink-bar {
  position: absolute;
  bottom: 0;
  height: 2px;
  // transition: 500ms cubic-bezier(0.35, 0, 0.25, 1) !important;
}
._mat-mdc-animation-noopable.mat-ink-bar {
  transition: none;
  animation: none;
}
.mat-mdc-tab-group-inverted-header .mat-ink-bar {
  bottom: auto;
  top: 0;
}
.cdk-high-contrast-active .mat-ink-bar {
  outline: solid 2px;
  height: 0;
}
.mat-mdc-tab-labels {
  display: flex;
}
[mat-align-tabs="center"] > .mat-mdc-tab-header .mat-mdc-tab-labels {
  justify-content: center;
}
[mat-align-tabs="end"] > .mat-mdc-tab-header .mat-mdc-tab-labels {
  justify-content: flex-end;
}
.mat-mdc-tab-label-container {
  display: flex;
  flex-grow: 0 !important;
  overflow: hidden;
  z-index: 1;
}
._mat-mdc-animation-noopable.mat-mdc-tab-list {
  transition: none;
  animation: none;
}
.mat-mdc-tab-labels {
  height: 48px;
  // padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  // opacity: 0.6;
  min-width: 160px !important;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
}
.mat-mdc-tab-labels:focus {
  outline: none;
}
.mat-mdc-tab.mat-mdc-tab-labels:hover{
  background-color: transparent !important;
}
.cdk-high-contrast-active .mat-mdc-tab-labels:focus {
  outline: dotted 2px;
  background-color: grey !important;
  outline-offset: -2px;
}
.mat-mdc-tab-labels.mat-mdc-tab-disabled {
  cursor: default;
}
.mat-mdc-tab-labels .mat-mdc-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #000000DE !important;
}
.mdc-tab__text-label{
  letter-spacing: initial !important;
}
.mdc-tab-indicator__content--underline {
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  border-top-style: solid;
}
.mdc-tab-indicator__content {
  transform-origin: left;
  // opacity: 0;
}
.mdc-tab-indicator .mdc-tab-indicator__content.mdc-tab-indicator__content--underline{
  transition-duration: 500ms !important;
}
.mdc-tab__text-label{
    color: rgba(0, 0, 0, .87);
}
.mdc-tab .mat-mdc-tab .mat-mdc-focus-indicator .mdc-tab--active .ng-star-inserted .mdc-tab-indicator--active{
  background-color: rgba(65, 64, 64, 0.185) !important;
}
@media (max-width: 599px) {
  .mat-mdc-tab-labels {
    min-width: 72px;
  }
}

