.wrapper {
  position: relative;
  top: 0;
  height: 100%;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 260px;
  background: $white-color;
  @include shadow-big();

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }

  &[data-background-color="black"] {
    background-color: #191919;
  }
  .logo-img {
    width: 30px;
    display: inline-block;
    max-height: 30px;
    img {
      width: 40px;
      top: 1px;
      position: absolute;
      left: 0;
    }
  }
  .sidebar-wrapper {
    position: relative;
    height: calc(100% - 75px);
    overflow: auto;
    width: 260px;
    z-index: 4;

    padding-bottom: 30px;

    .dropdown .dropdown-backdrop {
      display: none !important;
    }

    .navbar-form {
      border: none;
      box-shadow: none;

      .input-group {
        font-size: 1.7em;
        height: 36px;
        width: 78%;
        padding-left: 17px;
      }
    }

    > .nav,
    .user .user-info {
      [data-toggle="collapse"] ~ div > ul > li > a {
        span {
          display: inline-block;
          @extend .animation-transition-general;
        }

        .sidebar-normal {
          margin: 0;
          position: relative;
          transform: translateX(0px);
          opacity: 1;
          white-space: nowrap;
          display: block;
        }

        .sidebar-mini {
          text-transform: uppercase;
          width: 30px;
          margin-right: 15px;
          text-align: center;
          letter-spacing: 2px;
          position: relative;
          float: left;
          display: inherit;
        }

        i {
          font-size: 17px;
          line-height: 20px;
          width: 26px;
        }
      }
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav {
    margin-top: 20px;
    display: block;

    .caret {
      margin-top: 13px;
      position: absolute;
      right: 6px;
    }

    li {
      > a {
        &:hover,
        &:focus {
          background-color: transparent;
          outline: none;
        }
      }

      &:first-child > a {
        margin: 0 15px;
      }

      &:hover > a,
      & .dropdown-menu a:hover,
      & .dropdown-menu a:focus,
      &.active > [data-toggle="collapse"] {
        background-color: rgba(200, 200, 200, 0.2);
        color: $black-color;
        box-shadow: none;
      }

      &.active > [data-toggle="collapse"] {
        i {
          color: #a9afbb;
        }
      }

      &.active > a,
      &.active > a i {
        color: $white-color;
      }

      &.separator {
        margin: 15px 0;

        &:after {
          width: calc(100% - 30px);
          content: "";
          position: absolute;
          height: 1px;
          left: 15px;
          background-color: rgba(180, 180, 180, 0.3);
        }

        & + li {
          margin-top: 31px;
        }
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      font-size: 16px;
      position: relative;
      display: block;
      height: auto;
      white-space: nowrap;
      letter-spacing: 1px;

      .right{
        position: absolute;
        right: 1px;
        top: 0.5rem;
      }
    }

    i {
      font-size: 24px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
      color: #a9afbb;
    }
  }

  .nav li a,
  .nav li .dropdown-menu a {
    margin: 10px 15px 0;
    border-radius: $border-radius-base;
    color: $black-color;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: capitalize;
    font-size: $font-paragraph - 1;
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;

    &:after {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      content: "";
      display: block;
      background: #ffffff;
      opacity: 0.93;
    }
  }

  .logo {
    padding: 15px 0px;
    margin: 0;
    display: block;
    position: relative;
    z-index: 4;

    /*      &:after{
            content: '';
            position: absolute;
            bottom: 0;
            right: 15px;
            height: 1px;
            width: calc(100% - 30px);
            background-color: rgba(180,180,180, .3);

        } */

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
    }

    /*     .simple-text{
            text-transform: uppercase;
            padding: $padding-small-vertical $padding-zero;
            display: inline-block;
            font-size: 18px;
            color: $black-color;
            white-space: nowrap;
            font-weight: $font-weight-default;
            line-height: 30px;
            overflow: hidden;
            text-align: center;
            display: block;
        } */
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  &[data-background-color="black"] {
    @include sidebar-background-color($gray-base, $white-color);

    .nav li .dropdown-menu .dropdown-item {
      color: $white-color;
    }
  }
  &[data-background-color="lightgray"] {
    @include sidebar-background-color($gray, $white-color);

    .nav li .dropdown-menu .dropdown-item {
      color: $white-color;
    }

  }
  &[data-background-color="blue"] {
    @include sidebar-background-color($blue-100, $white-color);

    .nav li .dropdown-menu .dropdown-item {
      color: $white-color;
    }

  }

  &[data-color="purple"] {
    @include set-background-color-button($brand-primary);
  }
  &[data-color="azure"] {
    @include set-background-color-button($brand-info);
  }
  &[data-color="green"] {
    @include set-background-color-button($brand-success);
  }
  &[data-color="orange"] {
    @include set-background-color-button($brand-warning);
  }
  &[data-color="danger"] {
    @include set-background-color-button($brand-danger);
  }
  &[data-color="rose"] {
    @include set-background-color-button($brand-rose);
  }

  &[data-color="white"] {
    @include set-background-color-button($white-color);
    @include sidebar-active-color($black-color);
  }

  &[data-background-color="red"] {
    @include sidebar-background-color($brand-danger, $white-color);

    .user,
    .logo,
    .nav li.separator {
      &:after {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }

    .nav {
      li:hover:not(.active) > a,
      li.active > [data-toggle="collapse"] {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  &[data-image]:after,
  &.has-image:after {
    opacity: 0.77;
  }
}

.off-canvas-sidebar .navbar-collapse {
  .nav {
    > li > a,
    > li > a:hover {
      color: $white-color;
      margin: 0 15px;
    }

    > li > a:focus,
    > li > a:hover {
      background: rgba(200, 200, 200, 0.2);
    }
  }
}

.main-panel {
  position: relative;
  float: right;
  width: $sidebar-width;
  @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

  .main-content {
    margin-top: 70px;
    padding: 30px 15px;
    min-height: calc(100vh - 123px);
  }

  .footer {
    border-top: 1px solid #e7e7e7;
  }

  .navbar {
    margin-bottom: 0;
  }

  .header {
    margin-bottom: 30px;

    .title {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.perfect-scrollbar-on {
  .sidebar,
  .main-panel {
    height: 100%;
    max-height: 100%;
  }
}

.sidebar,
.main-panel,
.sidebar-wrapper {
  -webkit-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
  transition-duration: 0.2s, 0.2s, 0.35s;
  -webkit-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

.visible-on-sidebar-regular {
  display: inline-block !important;
}
.visible-on-sidebar-mini {
  display: none !important;
}

@media (min-width: 991px) {
  .sidebar-mini {
    .visible-on-sidebar-regular {
      display: none !important;
    }
    .visible-on-sidebar-mini {
      display: inline-block !important;
    }

    .sidebar,
    .sidebar .sidebar-wrapper {
      width: 80px;
    }

    .main-panel {
      width: $sidebar-mini-width;
    }

    .sidebar {
      display: block;
      font-weight: 200;
      z-index: 9999;

      .logo {
        a.logo-normal {
          opacity: 0;
          @include transform-translate-x(-25px);
        }
      }

      .sidebar-wrapper {
        > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user
          .user-info
          [data-toggle="collapse"]
          ~ div
          > ul
          > li
          > a
          .sidebar-normal,
        .user .user-info > a > span,
        > .nav li > a p {
          @include transform-translate-x(-25px);
          opacity: 0;
        }
      }
    }

    .sidebar:hover {
      width: 260px;

      .logo {
        a.logo-normal {
          opacity: 1;
          @include transform-translate-x(0px);
        }
      }

      .sidebar-wrapper {
        width: 260px;

        > .nav li > a p,
        > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal,
        .user
          .user-info
          [data-toggle="collapse"]
          ~ div
          > ul
          > li
          > a
          .sidebar-normal,
        .user .user-info > a > span {
          @include transform-translate-x(0px);
          opacity: 1;
        }
      }
    }
  }

  .sidebar {
    .nav .nav-item {
      &.active-pro {
        position: absolute;
        width: 100%;
        bottom: 13px;
      }
    }
  }
}


/*********/

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 260px;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
      0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  
    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid\9;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  
    .sidebar-wrapper {
      position: relative;
      height: calc(100% - 75px);
      overflow: hidden;
      width: 260px;
      z-index: 4;
      padding-bottom: 30px;
  
      .dropdown .dropdown-backdrop {
        display: none !important;
      }
  
      .navbar-form {
        border: none;
        box-shadow: none;
  
        .input-group {
          font-size: 1.7em;
          height: 36px;
          width: 78%;
          padding-left: 17px;
        }
      }
  
      .user .user-info [data-toggle="collapse"] {
        ~ div > ul > li > a span {
          display: inline-block;
        }
  
        ~ div > ul > li > a .sidebar-normal {
          margin: 0;
          position: relative;
          transform: translateX(0);
          opacity: 1;
          white-space: nowrap;
          display: block;
        }
  
        div > ul > li > a .sidebar-mini {
          text-transform: uppercase;
          width: 30px;
          margin-right: 15px;
          text-align: center;
          letter-spacing: 2px;
          position: relative;
          float: left;
          display: inherit;
        }
  
          div > ul > li > a i {
          font-size: 17px;
          line-height: 20px;
          width: 26px;
        }
      }
  
      > .nav [data-toggle="collapse"] ~ div > ul > li > a span {
        display: inline-block;
      }
  
      > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-normal {
        margin: 0;
        position: relative;
        transform: translateX(0);
        opacity: 1;
        white-space: nowrap;
        display: block;
      }
  
      > .nav [data-toggle="collapse"] ~ div > ul > li > a .sidebar-mini {
        text-transform: uppercase;
        width: 30px;
        margin-right: 15px;
        text-align: center;
        letter-spacing: 2px;
        position: relative;
        float: left;
        display: inherit;
      }
  
      > .nav [data-toggle="collapse"] ~ div > ul > li > a i {
        font-size: 17px;
        line-height: 20px;
        width: 26px;
      }
    }
  
  /*   .sidebar-background {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: block;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: 50%;
  
      &:after {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        content: "";
        display: block;
        background: #fff;
        opacity: 0.93;
      }
    }
   */
    .logo {
      padding: 15px 0;
      margin: 0;
      display: block;
      position: relative;
      z-index: 4;
  
      a {
        letter-spacing: 2px;
        &.logo-mini {
          float: left;
          width: 45px;
          text-align: center;
          margin-left: 23px;
          margin-right: 15px;
          position: relative;
        }
  
        &.logo-normal {
          display: block;
          opacity: 1;
          transform: translateZ(0);
        }
      }
  
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 15px;
        height: 1px;
        width: calc(100% - 30px);
        background-color: hsla(0, 0%, 71%, 0.3);
      }
  
      p {
        float: left;
        font-size: 20px;
        margin: 10px;
        color: #fff;
        line-height: 20px;
        
      }
  
      .simple-text {
        text-transform: uppercase;
        padding: 5px 0;
        display: inline-block;
        font-size: 18px;
        color: $yellow;
        white-space: nowrap;
        font-weight: 400;
        line-height: 30px;
        overflow: hidden;
      }
    }
  
    .logo-tim {
      border-radius: 50%;
      border: 1px solid #333;
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;
  
      img {
        width: 60px;
        height: 60px;
      }
    }
  
    .user {
      padding-bottom: 20px;
      margin: 20px auto 0;
      position: relative;
  
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 15px;
        height: 1px;
        width: calc(100% - 30px);
        background-color: hsla(0, 0%, 71%, 0.3);
      }
  
      .photo {
        width: 34px;
        height: 34px;
        overflow: hidden;
        float: left;
        z-index: 5;
        margin-right: 11px;
        border-radius: 50%;
        margin-left: 23px;
        box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
          0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  
        img {
          width: 100%;
        }
      }
  
      a {
        color: #3c4858;
        padding: 0.5rem 15px;
        white-space: nowrap;
      }
  
      .user-info {
        > a {
          display: block;
          line-height: 18px;
          font-size: 14px;
  
          > span {
            display: block;
            position: relative;
            opacity: 1;
            letter-spacing: 1px;
          }
        }
  
        .caret {
          position: absolute;
          top: 8px;
          right: 15px;
        }
      }
    }
  /*   &[data-background-color="blue"] {
      .nav .nav-item {
        .nav-link {
          color: #fff;
        }
  
        i {
          color: hsla(0, 0%, 100%, 0.8);
        }
  
        &.active [data-toggle="collapse"],
        &:hover [data-toggle="collapse"] {
          color: #fff;
        }
  
        &.active [data-toggle="collapse"] i,
        &:hover [data-toggle="collapse"] i {
          color: hsla(0, 0%, 100%, 0.8);
        }
      }
  
      .simple-text,
      .user a {
        color: #fff;
      }
  
      .sidebar-background:after {
        background: #0032a0;
        opacity: .9;
      }
  
      .nav li .dropdown-menu .dropdown-item {
        color: #fff;
      }
    } */
    /* &[data-background-color="black"] {
      .nav .nav-item {
        .nav-link {
          color: #fff;
        }
  
        i {
          color: hsla(0, 0%, 100%, 0.8);
        }
  
        &.active [data-toggle="collapse"],
        &:hover [data-toggle="collapse"] {
          color: #fff;
        }
  
        &.active [data-toggle="collapse"] i,
        &:hover [data-toggle="collapse"] i {
          color: hsla(0, 0%, 100%, 0.8);
        }
      }
  
      .simple-text,
      .user a {
        color: #fff;
      }
  
      .sidebar-background:after {
        background: #002646;
        opacity: 0.8;
      }
  
      .nav li .dropdown-menu .dropdown-item {
        color: #fff;
      }
    } */
  
   
  
    &[data-color="purple"] li.active > a {
      background-color: #9c27b0;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(156, 39, 176, 0.4);
    }
  
    &[data-color="azure"] li.active > a {
        color: #fff;
        opacity: 1;
        background: rgba(255,255,255,.23);;
    }
  
    &[data-color="green"] li.active > a {
      background-color: #4caf50;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(76, 175, 80, 0.4);
    }
  
    &[data-color="orange"] li.active > a {
      background-color: #ff9800;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(255, 152, 0, 0.4);
    }
  
    &[data-color="danger"] li.active > a {
      background-color: #f44336;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(244, 67, 54, 0.4);
    }
  
    &[data-color="rose"] li.active > a {
      background-color: #e91e63;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(233, 30, 99, 0.4);
    }
  
   /*  &[data-color="white"] {
      li.active > a {
        background-color: #fff;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
          0 7px 10px -5px hsla(0, 0%, 100%, 0.4);
      }
  
      .nav .nav-item.active > a:not([data-toggle="collapse"]) {
        color: #3c4858;
        opacity: 1;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
          0 7px 10px -5px rgba(60, 72, 88, 0.4);
  
        i {
          color: rgba(60, 72, 88, 0.8);
        }
      }
    }
  
    &.has-image:after,
    &[data-image]:after {
      opacity: 0.77;
    }*/
  } 
  
  .main-panel {
    position: relative;
    float: right;
    width: calc(100% - 260px);
    transition: 0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1);
  
    > {
      .content {
        margin-top: 70px;
        padding: 30px 15px;
        min-height: calc(100% - 123px);
      }
  
      .footer {
        border-top: 1px solid #e7e7e7;
      }
  
      .navbar {
        margin-bottom: 0;
      }
    }
  
    .header {
      margin-bottom: 30px;
  
      .title {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
  