@import "../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

@import "../../../node_modules/bootstrap/scss/functions";
@import"./bootstrap-variables";

@import "../../../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "./core/variables";
@import "./core/mixins";
@import "bootstrap/scss/bootstrap";

@import "./core/buttons";
@import "./core/checkboxes";
@import "./core/radios";
@import "./core/forms";
@import "./core/input-group";
@import "./core/images";
@import "./core/navbar";
@import "./core/alerts";
@import "./core/type";
@import "./core/tabs";
@import "./core/tooltip";
@import "./core/popover";
@import "./core/dropdown";
@import "./core/togglebutton";
@import "./core/ripples";
@import "./core/footers";
@import "./core/sidebar-and-main-panel";
@import "./core/fixed-plugin";
@import "./core/tables";
@import "./core/misc";

@import "./core/cards";
@import "./core/cards/card-stats";
@import "./core/cards/card-profile";
@import "./core/cards/card-plain";

@import "./core/plugins/animate";
@import "./core/plugins/chartist";
@import "./core/plugins/perfect-scrollbar";

@import "./core/responsive";

@import "./core/angular-modal.scss";
@import "./material-dashboard.scss";
.toast-message a {
  text-decoration: underline  !important;
  color: #FFFFFF;
}
@media print {
  .mat-checkbox-background {
    border: 2px solid rgba(0, 0, 0, 0) !important;
    background-color: transparent !important;
  }
  .mat-checkbox-checked {
    .mat-checkbox-checkmark {
      background: black !important;
      .mat-checkbox-checkmark-path {
        stroke: #0032A0 !important;
        stroke-width: 5px !important;
      }
    }
  }
  .bg-blue{
   color: white !important;
  }
.print-c {

  .mat-radio-inner-circle {
    box-shadow: 0 0 0 1000px #4340ff inset !important;
  }
  .mat-radio-outer-circle{
    border-color: #4340ff !important;
    background-color: #ffffff;
  }

}
}
